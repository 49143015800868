<template>
<div class="section">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>

</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../../store/UserStore"

export default {
    props: {
        isShowPDFDialog: Boolean,
        monthSelected: Date,
        physicalInventoryDate: Date,
        currentAssetItems: Array,
        currentLiabilityItems: Array,
        fuelAssetItems: Array,
        merchAssetItems: Array,
        nonInventory: Array,
        
        totalPreviousPhysicalFuelInventoryVolume: Number,
        totalPreviousPhysicalFuelInventory: Number,
        totalFuelPurchase: Number,
        totalFuelInventoryCost: Number,
        totalFuelSalesVolume: Number,
        totalFuelCost: Number,

        totalPreviousPhysicalMerchInventory: Number,
        totalMerchandiseInventoryCost: Number,
        totalMerchandiseInventoryPrice: Number,
        totalMerchandiseCost: Number,

        totalPreviousPhysicalCigInventory: Number,
        totalCigInventoryCost: Number,
        totalCigPurchase: Number,
        totalCigCost: Number,

        totalPreviousPhysicalInventory: Number,
        totalInventoryPurchase: Number,

        netAdjThisMonth: Number,
        netAdjMonthBeg: Number,
        netAdjMonthEnd: Number,
        inventoryBookValue: Number,
        netInventoryDifference: Number,
        previousMonthlyAssets: Object,
        expenseRecords: Array,
        consignedGoods: Array,
        atmRecord: Array,
        atmBalanceRecord: Array,
        atmReportDisbursed: Array,
        totalCashLoadedInAtm: Number,
        previousAtmBalance: Number,
        profitAndLossData: Object,
        inventoryReconcileItems: Object,
        lottery: Object,
    },

    data() {
        return {
            pdfDoc: {},
            helveticaFont: {},
            pageNumber: 0,
            page: {},
            isShowPDFDialogLocal: false,
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'})
        }
    },

    methods: {

        closePDFDialog() {
            this.isShowPDFDialogLocal = false
            this.$emit('close-pdf-dialog')
        },

        createPdf: async(gthis) => {

            gthis.pdfDoc = await PDFDocument.create()
            gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
            gthis.pdfDoc.setTitle('Month End Summary Report')
            gthis.pdfDoc.setSubject('support@veecli.com')
            gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
            gthis.pdfDoc.setProducer('VEECLi Backoffice Software')

            gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)

            await gthis.pageHeader(gthis)
            // var text = ""
            var leftrow = 680
            var leftLabelCol = 30
            var leftAmountCol = 300

            var rightrow = 680
            var rightLabelCol = 330
            var rightAmountCol = 570

            gthis.drawRowHeadTextLeft(gthis, rightrow, rightLabelCol, 11, 'LIABILITIES')
            rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol,2,1)

            // Liabilities
            rightrow = await gthis.getNextRow(gthis,rightrow, 4)
            var totLiabilities = 0
            for (const liability of gthis.currentLiabilityItems) {
                
                gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, liability.name)
                gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, liability.multiplicand)
                totLiabilities += liability.multiplicand
                rightrow = await gthis.drawLine(gthis, rightrow,rightLabelCol,rightAmountCol)
                rightrow = await gthis.getNextRow(gthis,rightrow,3)
            }


            // row = 680
            gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 11, 'ASSETS')
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol,2,1)

            // Assets
            leftrow = await gthis.getNextRow(gthis,leftrow, 4)
            var totAsset = 0
            for (const asset of gthis.currentAssetItems) {
                
                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, asset.name)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, asset.multiplicand)
                totAsset += asset.multiplicand
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)
            }

            // gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Total Current Assets")
            // gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, totAsset)
            // gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 280, 16, 0.1)
            // leftrow = await gthis.getNextRow(gthis,leftrow,4)

            var showInvItem = false
            if (showInvItem) {
                // Fuel Physical Inventory Total
                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Fuel Inventory")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol-75, 10, gthis.totalFuelInventoryCost)
                // totAsset += gthis.totalFuelInventoryCost
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                // Merchandise Physical Inventory Total
                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Merchandise Inventory")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol-75, 10, gthis.totalMerchandiseInventoryCost - gthis.totalCigInventoryCost)
                // totAsset += gthis.totalMerchandiseInventoryCost
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)  

                // Cigarette Physical Inventory Total
                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Cigarette Inventory")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol-75, 10, gthis.totalCigInventoryCost)
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3) 
            } 

            // Physical Inventory Total
            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Total Physical Inventory")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.totalFuelInventoryCost + gthis.totalMerchandiseInventoryCost)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)              
            
            totAsset = totAsset + gthis.totalFuelInventoryCost + gthis.totalMerchandiseInventoryCost
            // Set Total Liability Row to same as Total Assets Row
            // rightrow = leftrow

            // Total Liabilities
            gthis.drawRowHeadTextLeft(gthis, rightrow, rightLabelCol, 10, "Total Liabilities")
            gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, totLiabilities)
            gthis.drawTotalRectangle(gthis, rightrow, rightLabelCol, 250, 16, 0.1)
            rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol-5,rightAmountCol+5,2,.4)            
            rightrow = await gthis.getNextRow(gthis,rightrow,3)

            // Total Assets
            gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 10, "Total Assets")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, totAsset)
            gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 280, 16, 0.1)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol-5,leftAmountCol+5,2,.4)            

            rightrow = await gthis.getNextRow(gthis,rightrow,3)
            leftrow = await gthis.getNextRow(gthis,leftrow,6)

            // Net Paid Assets
            gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, "Net Business Equity")
            gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, totAsset-totLiabilities)
            rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol)
            rightrow = await gthis.getNextRow(gthis,rightrow,6)

            // leftrow = await gthis.getNextRow(gthis,leftrow,3)
            
            // leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol,2,1)

            rightrow = await gthis.getNextRow(gthis,rightrow,1)
            // var show = false

            // rightrow = await gthis.getNextRow(gthis,rightrow,3)

            // leftrow = await gthis.getNextRow(gthis,leftrow,8)

            rightrow = leftrow
            // if (show) {
                // Other Stock/Consignment items
                gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 11, 'PHYSICAL INVENTORY')
                gthis.drawTextRight(gthis, leftrow, leftAmountCol, 10, "DATE : " + moment(gthis.physicalInventoryDate).format("MM/DD/YYYY"))

                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol,2,1)
                // row = await gthis.getNextRow(gthis,row,3)

                // Physical Inventory - Fuel
                leftrow = await gthis.getNextRow(gthis,leftrow, 3)
                var totFuelInv = 0
                var totFuelInvVol = 0
                for (const fuel of gthis.fuelAssetItems) {
                    
                    gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, gthis.toTitleCase(fuel.name))
                    gthis.drawTextRight(gthis, leftrow, leftAmountCol-150, 10, fuel.multiplicand + " gal.")
                    gthis.drawTextRight(gthis, leftrow, leftAmountCol-75, 10, "$"+fuel.multiplier.toFixed(2)  + "/gal.")
                    gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, fuel.multiplicand * fuel.multiplier)
                    totFuelInv += fuel.multiplicand * fuel.multiplier
                    totFuelInvVol += fuel.multiplicand
                    leftrow = await gthis.drawLine(gthis, leftrow,leftLabelCol,leftAmountCol)
                    leftrow = await gthis.getNextRow(gthis,leftrow,3)
                }

                // Total Fuel Inventory
                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Total Fuel")
                gthis.drawTextRight(gthis, leftrow, leftAmountCol-150, 10, totFuelInvVol + " gal.")
                gthis.drawTextRight(gthis, leftrow, leftAmountCol-75, 10, "$"+(totFuelInv/totFuelInvVol).toFixed(2)  + "/gal.")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, totFuelInv)
                gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 280, 16, 0.1)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                // Physical Inventory - Merchandise
                rightrow = await gthis.getNextRow(gthis,rightrow, 4)
                // var totMerchInv = 0

                // var cigItem = {}
                
                for (const merch of gthis.merchAssetItems) {
                    // if (merch.name == "Cigarette") {
                    //     cigItem = merch
                    //     continue
                    // }
                    gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, merch.name)
                    gthis.drawTextRightCurrency(gthis, rightrow, 450, 10, merch.multiplicand)
                    gthis.drawTextRight(gthis, rightrow, 500, 10, merch.multiplier.toFixed(2)  + "%")
                    gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, gthis.calculateMercandiseCost(merch.multiplicand, merch.multiplier) )
                    // totMerchInv += gthis.calculateMercandiseCost(merch.multiplicand, merch.multiplier)
                    rightrow = await gthis.drawLine(gthis, rightrow,rightLabelCol,rightAmountCol)
                    rightrow = await gthis.getNextRow(gthis,rightrow,3)
                }

                // var totCigInv = 0

                // Total Physical Inventory
                gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, "Total")
                gthis.drawTextRightCurrency(gthis, rightrow, 450, 10, gthis.totalMerchandiseInventoryPrice)
                gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, gthis.totalMerchandiseInventoryCost)
                gthis.drawTotalRectangle(gthis, rightrow, rightLabelCol, 250, 16, 0.1)
                rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol-5,rightAmountCol+5,2,.4)            

            // }

            rightrow = await gthis.getNextRow(gthis,rightrow,8)

            if (gthis.merchAssetItems.length > 0) {
                leftrow = rightrow
            } else {
                leftrow -= 10
            }
            
            if (gthis.totalPreviousPhysicalInventory) {
                gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 11, 'INVENTORY RECONCILIATION - COST')
                gthis.drawTextRight(gthis, leftrow, leftAmountCol, 10, "FUEL")
                gthis.drawTextRight(gthis, leftrow, leftAmountCol+100, 10, "MERCHANDISE")
                gthis.drawTextRight(gthis, leftrow, leftAmountCol+180, 10, "CIGARETTE")
                gthis.drawTextRight(gthis, leftrow, leftAmountCol+270, 10, "TOTAL")
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol,2,1)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Previous Balance")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.totalPreviousPhysicalFuelInventory)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, gthis.getNumber(gthis.totalPreviousPhysicalMerchInventory) - gthis.getNumber(gthis.totalPreviousPhysicalCigInventory))
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.getNumber(gthis.totalPreviousPhysicalCigInventory))
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, gthis.getNumber(gthis.totalPreviousPhysicalInventory))                
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Add - Purchases")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.totalFuelPurchase)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, gthis.getNumber(gthis.totalInventoryPurchase) - gthis.getNumber(gthis.totalCigPurchase))
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.getNumber(gthis.totalCigPurchase))
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, gthis.getNumber(gthis.totalInventoryPurchase) + gthis.getNumber(gthis.totalFuelPurchase))                
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Less - Cost of Goods Sold")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.inventoryReconcileItems.fuelSalesCost)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, (gthis.inventoryReconcileItems.merchSalesCost) )
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.getNumber(gthis.totalCigCost) * -1)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, (gthis.inventoryReconcileItems.totalSalesCost) )                
                // leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                var totalFuelBookBalance = (gthis.getNumber(gthis.totalPreviousPhysicalFuelInventory) + gthis.totalFuelPurchase + gthis.inventoryReconcileItems.fuelSalesCost)
                var totalMerchBookBalance = gthis.getNumber(gthis.inventoryReconcileItems.merchBookBalanceCost)
                var totalCigBookBalance = gthis.getNumber(gthis.totalPreviousPhysicalCigInventory) + gthis.getNumber(gthis.totalCigPurchase) - gthis.getNumber(gthis.totalCigCost)
                var totalBookBalance = gthis.getNumber(gthis.inventoryReconcileItems.totalBookBalanceCost)
                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Book Balance")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, totalFuelBookBalance)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, totalMerchBookBalance)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, totalCigBookBalance)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, totalBookBalance) 
                gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 550, 16, 0.1)               
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)               
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                var totalPhysicalInventory = gthis.totalFuelInventoryCost + gthis.totalMerchandiseInventoryCost
                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Physical Inventory")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.totalFuelInventoryCost)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, gthis.totalMerchandiseInventoryCost - gthis.totalCigInventoryCost)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.totalCigInventoryCost)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, totalPhysicalInventory) 
                // leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Inventory Variance")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, (gthis.totalFuelInventoryCost - totalFuelBookBalance))
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, (gthis.totalMerchandiseInventoryCost - gthis.totalCigInventoryCost - gthis.inventoryReconcileItems.merchBookBalanceCost))
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.totalCigInventoryCost - totalCigBookBalance)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, gthis.inventoryReconcileItems.totalVarianceCost)                
                gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 550, 16, 0.1)               
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)                
            }

            leftrow = await gthis.getNextRow(gthis,leftrow,2)
            rightrow = leftrow
            
            if (gthis.totalPreviousPhysicalInventory) {
                gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 11, 'INVENTORY RECONCILIATION - SALES')
                gthis.drawTextRight(gthis, leftrow, leftAmountCol, 10, "FUEL")
                gthis.drawTextRight(gthis, leftrow, leftAmountCol+100, 10, "MERCHANDISE")
                gthis.drawTextRight(gthis, leftrow, leftAmountCol+180, 10, "CIGARETTE")
                gthis.drawTextRight(gthis, leftrow, leftAmountCol+270, 10, "TOTAL")
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol,2,1)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Previous Balance")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.inventoryReconcileItems.prevFuelInventoryPrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, gthis.inventoryReconcileItems.merchPrevBalance )
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.inventoryReconcileItems.prevCigInventoryPrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, gthis.inventoryReconcileItems.totalPreviousPhysicalPrice)                
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Add - Purchases")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.inventoryReconcileItems.fuelPurchasePrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, gthis.inventoryReconcileItems.merchPurchasePrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.inventoryReconcileItems.cigPurchasePrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, gthis.inventoryReconcileItems.totalPurchasePrice)                
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Less - Sales")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.inventoryReconcileItems.fuelSalesPrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, gthis.inventoryReconcileItems.merchSalesPrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.inventoryReconcileItems.cigSalesPrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, gthis.inventoryReconcileItems.totalSalesPrice )                
                // leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                // var totalFuelBookBalance = (gthis.totalPreviousPhysicalFuelInventory + gthis.totalFuelPurchase - gthis.totalFuelCost)
                // var totalMerchBookBalance = (gthis.totalPreviousPhysicalMerchInventory - gthis.totalPreviousPhysicalCigInventory + gthis.totalInventoryPurchase - gthis.totalCigPurchase - gthis.totalMerchandiseCost + gthis.totalCigCost)
                // var totalCigBookBalance = gthis.totalPreviousPhysicalCigInventory + gthis.totalCigPurchase - gthis.totalCigCost
                // var totalBookBalance = totalMerchBookBalance + totalCigBookBalance + totalFuelBookBalance
                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Book Balance")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.inventoryReconcileItems.fuelBookBalancePrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, gthis.inventoryReconcileItems.merchBookBalancePrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.inventoryReconcileItems.cigBookBalancePrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, gthis.inventoryReconcileItems.totalBookBalancePrice)                
                gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 550, 16, 0.1)               
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)               
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                // var totalPhysicalInventory = gthis.totalFuelInventoryCost + gthis.totalMerchandiseInventoryCost
                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Physical Inventory")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.inventoryReconcileItems.fuelPhysicalInventoryPrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, gthis.inventoryReconcileItems.merchPhysicalInventory)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.inventoryReconcileItems.cigTotalInventoryPrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, gthis.inventoryReconcileItems.totalPhysicalPrice) 
                // leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)

                gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Inventory Variance")
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.inventoryReconcileItems.fuelVariance)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+100, 10, gthis.inventoryReconcileItems.merchVariance )
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+180, 10, gthis.inventoryReconcileItems.cigVariancePrice)
                gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol+270, 10, gthis.inventoryReconcileItems.totalVariancePrice)                
                gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 550, 16, 0.1)               
                leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,rightAmountCol)
                leftrow = await gthis.getNextRow(gthis,leftrow,3)                
            }

            // leftrow = await gthis.getNextRow(gthis,leftrow,2)
            var excludeText = "** Excluded Car Wash, Fuel, Gaming, Instant Lottery and Lottery from Merchandise."
            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 8, excludeText)

            await gthis.pageHeader(gthis)
            leftrow = 700
            rightrow = 700

            leftrow = await gthis.getNextRow(gthis,leftrow,6)
            gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 11, 'INSTANT LOTTERY SUMMARY')
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol,2,1)
            leftrow = await gthis.getNextRow(gthis,leftrow,4)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Open Balance")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.lottery.openBalance)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "   Add - Receips")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.lottery.received)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "   Less - Returns")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.lottery.returned * -1)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)
            
            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "   Less - Inside Sales")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.lottery.instantSales * -1)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "   Less - Outside Sales")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.lottery.machineWithdraw)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            var lotteryBookBalance = gthis.lottery.openBalance + gthis.lottery.received - 
                gthis.lottery.returned - gthis.lottery.instantSales - gthis.lottery.machineWithdraw
            gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 10, "Book Balance")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, lotteryBookBalance)
            gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 280, 16, 0.1)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol-5,leftAmountCol+5,2,.4)            
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Close Balance")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.lottery.closeBalance)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            
            gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 10, "Variance")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, (gthis.lottery.closeBalance - lotteryBookBalance) )
            gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 280, 16, 0.1)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol-5,leftAmountCol+5,2,.4)            
            leftrow = await gthis.getNextRow(gthis,leftrow,3)




            // leftrow = await gthis.getNextRow(gthis,leftrow,6)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)
            gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 11, 'ATM CASH SUMMARY')
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol,2,1)
            leftrow = await gthis.getNextRow(gthis,leftrow,4)
            

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Previous Balance")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, (gthis.previousAtmBalance ? gthis.previousAtmBalance : 0))
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Cash Loaded")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, (gthis.totalCashLoadedInAtm ? gthis.totalCashLoadedInAtm : 0))
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            var atmBalance = 0
            for (const consign of gthis.atmBalanceRecord) {
                // if (consign.name == "ATM Balance") {
                    atmBalance = consign.multiplicand * consign.multiplier
                    gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, consign.name)
                    gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, atmBalance)
                    leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
                    leftrow = await gthis.getNextRow(gthis,leftrow,3)
                // }
            }

            var cashDisbursed = (gthis.totalCashLoadedInAtm ? gthis.totalCashLoadedInAtm : 0) +
                        (gthis.previousAtmBalance ? gthis.previousAtmBalance : 0) - atmBalance
                        
            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Cash Disbursed")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, cashDisbursed)
            gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 280, 16, 0.1)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            var atmReportAmount = 0
            for (const consign of gthis.atmReportDisbursed) {
                // if (consign.name == "ATM Balance") {
                    atmReportAmount = consign.multiplicand * consign.multiplier
                    gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, consign.name)
                    gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, atmReportAmount)
                    leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
                    leftrow = await gthis.getNextRow(gthis,leftrow,3)
                // }
            }            

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "ATM Variance")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, cashDisbursed - atmReportAmount)
            gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 280, 16, 0.1)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            leftrow = await gthis.getNextRow(gthis,leftrow,3)
            gthis.drawRowHeadTextLeft(gthis, leftrow, leftLabelCol, 11, 'CIGARETTE SUMMARY')
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol,2,1)
            leftrow = await gthis.getNextRow(gthis,leftrow,4)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Open Count")
            gthis.drawTextRight(gthis, leftrow, leftAmountCol, 10, gthis.profitAndLossData.previousCigaretteCloseCount.toString())
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Buy Count")
            gthis.drawTextRight(gthis, leftrow, leftAmountCol, 10, gthis.profitAndLossData.cigBuyCount.toString())
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Sale Count")
            gthis.drawTextRight(gthis, leftrow, leftAmountCol, 10, gthis.profitAndLossData.cigDeptSaleItemCount.toString())
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)
            
            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Close Count")
            gthis.drawTextRight(gthis, leftrow, leftAmountCol, 10, gthis.profitAndLossData.cigCloseCount.toString())
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)            

            var cigVariance = gthis.profitAndLossData.previousCigaretteCloseCount + gthis.profitAndLossData.cigBuyCount - gthis.profitAndLossData.cigDeptSaleItemCount - gthis.profitAndLossData.cigCloseCount
            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Variance Count")
            gthis.drawTextRight(gthis, leftrow, leftAmountCol, 10, cigVariance.toString())
            gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 280, 16, 0.1)            
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,6)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Buy Amount")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.totalCigPurchase)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Buy Cost/Pack ")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.totalCigPurchase / gthis.profitAndLossData.cigBuyCount)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)

            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Sales Amount")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.profitAndLossData.cigDeptSales)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)   
            
            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Sale Price/Pack")
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, gthis.profitAndLossData.cigDeptSales / gthis.profitAndLossData.cigDeptSaleItemCount)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)            

            var profitPerCigPack = (gthis.profitAndLossData.cigDeptSales / gthis.profitAndLossData.cigDeptSaleItemCount) -
                (gthis.totalCigPurchase / gthis.profitAndLossData.cigBuyCount)
            gthis.drawTextLeft(gthis, leftrow, leftLabelCol, 10, "Profit/Pack")
            gthis.drawTotalRectangle(gthis, leftrow, leftLabelCol, 280, 16, 0.1)
            gthis.drawTextRightCurrency(gthis, leftrow, leftAmountCol, 10, profitPerCigPack)
            leftrow = await gthis.drawLine(gthis, leftrow, leftLabelCol,leftAmountCol)
            leftrow = await gthis.getNextRow(gthis,leftrow,3)
                        
            rightrow = await gthis.getNextRow(gthis,rightrow,6)

            gthis.drawRowHeadTextLeft(gthis, rightrow, rightLabelCol, 11, 'INVENTORY PURCHASES')
            leftrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol,2,1)
            rightrow = await gthis.getNextRow(gthis,rightrow,4)
            // expenseRecords
            for (const expense of gthis.sortArrayByProp(gthis.profitAndLossData.inventoryExpenseList, "deptMapping")) {
                if (expense.amount !== 0.00) {
                    gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, expense.deptMapping)
                    gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, expense.expenseCost)
                    rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol)
                    rightrow = await gthis.getNextRow(gthis,rightrow,3)
                }
            }
            rightrow = await gthis.getNextRow(gthis,rightrow,3)

            // Other Non-Inventory items
            gthis.drawRowHeadTextLeft(gthis, rightrow, rightLabelCol, 11, 'NON-INVENTORY ITEMS')
            rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol,2,1)
            // var totConsign = 0
            rightrow = await gthis.getNextRow(gthis,rightrow,3)

            for (const consign of gthis.nonInventory) {
                if (consign.multiplicand * consign.multiplier !== 0.00) {
                    gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, consign.name)
                    gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, consign.multiplicand * consign.multiplier)
                    rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol)
                    rightrow = await gthis.getNextRow(gthis,rightrow,3)
                }
            }
            for (const consign of gthis.consignedGoods) {
                if (consign.multiplicand * consign.multiplier !== 0.00) {
                    gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, consign.name)
                    gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, consign.multiplicand * consign.multiplier)
                    rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol)
                    rightrow = await gthis.getNextRow(gthis,rightrow,3)
                }
            }  

            gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, "Bank Deposit - General")
            gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, gthis.profitAndLossData.deposits.bankDepositGeneral)
            rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol)
            rightrow = await gthis.getNextRow(gthis,rightrow,3)

            gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, "Bank Deposit - Other")
            gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, gthis.profitAndLossData.deposits.bankDepositLottery)
            rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol)
            rightrow = await gthis.getNextRow(gthis,rightrow,3)

            gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, "Other Cash Disbursements")
            gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, gthis.profitAndLossData.deposits.bankDepositAtm)
            rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol)
            rightrow = await gthis.getNextRow(gthis,rightrow,3)

            rightrow = await gthis.getNextRow(gthis,rightrow,3)
            gthis.drawRowHeadTextLeft(gthis, rightrow, rightLabelCol, 11, 'EXPENSES')
            leftrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol,2,1)
            rightrow = await gthis.getNextRow(gthis,rightrow,4)
            // expenseRecords
            for (const expense of gthis.expenseRecords) {
                if (expense.amount !== 0.00) {
                    gthis.drawTextLeft(gthis, rightrow, rightLabelCol, 10, expense.expenseTypeName)
                    gthis.drawTextRightCurrency(gthis, rightrow, rightAmountCol, 10, expense.amount)
                    rightrow = await gthis.drawLine(gthis, rightrow, rightLabelCol,rightAmountCol)
                    rightrow = await gthis.getNextRow(gthis,rightrow,3)
                }
            }

            rightrow = await gthis.getNextRow(gthis,rightrow,3)

            // Create PDF Document
            const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        async loadData() {
            this.isShowPDFDialogLocal = true
            this.createPdf(this)
            this.pageNumber = 0           
        },

        pageHeader: async(gthis, titleText)  => {

            if (!titleText) {
                titleText = "MONTH END SUMMARY REPORT"
            }
            gthis.pageNumber++

            gthis.page = gthis.pdfDoc.addPage(PageSizes.Letter);
            
            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);

            gthis.page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 14, 
                })

            var row = 725
            var reportHead = titleText
            textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 13);

            gthis.page.drawText(reportHead, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 15, 
                })            

            row = 700

            var text = moment(gthis.monthSelected).format("MMMM YYYY")
            textWidth = gthis.helveticaFont.widthOfTextAtSize(text, 12);

            gthis.page.drawText(text, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 12, 
                })

            await gthis.pageFooter(gthis)                         
                
        },

        veecliLogo: async(gthis) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await gthis.pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            gthis.page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                opacity: 0.75,
            })
            gthis.page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })                    
        },

        pageFooter: async(gthis) => {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            gthis.page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })

            var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
            gthis.page.drawText('Page ' + gthis.pageNumber, { 
                x: 590 - textWidth, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })
            await gthis.veecliLogo(gthis)
        },


        getNextRow: async(gthis,row, count) => {
  
            if (!count) count = 1
            if (row > 50) {
                return row - (5 * count)
            } else {
                await gthis.pageHeader(gthis)

                // Set Line to start records
                return 680
            }
            
        },

        drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize, 
            })
            return row
        },

        drawRowTextLeft: async (gthis, row, text) => {
            row = await gthis.getNextRow(gthis,row, 2)
            text = gthis.toTitleCase(text)

            gthis.page.drawText(text, { 
                x: 45, 
                y: row, 
                size: 9, 
            })
            return row
        },

        drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRight: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart - textWidth - 40, y: row },
                end: { x: colStart, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },

        drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
            gthis.page.drawText(text+" gal.", { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
            if (isNaN(text)) {
                text = 0
            }
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(text), fontSize)
            gthis.page.drawText(gthis.dollarUSLocale.format(text), { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },        

        drawLine: async (gthis, row, beg, end,thickness,opacity) => {
            row = await gthis.getNextRow(gthis,row, 1)
            if (!beg) beg = 45
            if (!end) end = 500
            if (!thickness) thickness = 1
            if (!opacity) opacity = 0.2
            gthis.page.drawLine({
                start: { x: beg, y: row },
                end: { x: end, y: row },
                thickness: thickness,
                opacity: opacity,
            })
            return row
        },

        drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
            gthis.page.drawRectangle({
                x: colStart-4,
                y: row-4,
                width: width,
                height: height,
                rotate: degrees(0),
                borderWidth: 0,
                borderColor: grayscale(0.5),
                color: rgb(0, 0, 0),
                opacity: opacity,
                borderOpacity: 0.75,
            })
        },
    }
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>